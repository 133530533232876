import {AppMutations} from "./mutations";
import {ActionTree} from "vuex";
import {AppState, ObjectData, Projects, Stats} from "@/store/modules/app/types";
import {RootState} from "@/store/types";

export enum AppActions {
    SET_OBJECT_DATA = 'setObjectData',
    SET_CURRENT_VIEW_LIST = 'setCurrentViewList',
    SET_BUILDING_DATA = 'setBuildingData',
    SET_CONNECTION_ERROR = 'connectionError',
    SET_LOGGED_IN = 'setLoggedIn',
    SET_PROJECTS = 'setProject',
    SET_VISITED = 'setVisited',
    SET_STATS = 'setStats',
    SET_MILESTONES = 'setMilestones'
}

export const actions: ActionTree<AppState, RootState> = {
    [AppActions.SET_OBJECT_DATA]({commit, state, dispatch}, objectData: Array<ObjectData>): void {
        commit(AppMutations.SET_OBJECT_DATA, objectData);
    },
    [AppActions.SET_CURRENT_VIEW_LIST]({commit, state, dispatch}, viewList: Array<ObjectData>): void {
        commit(AppMutations.SET_CURRENT_VIEW_LIST, viewList);
    },
    [AppActions.SET_BUILDING_DATA]({commit, state, dispatch}, isBuildingData: boolean): void {
        commit(AppMutations.SET_BUILDING_DATA, isBuildingData);
    },
    [AppActions.SET_CONNECTION_ERROR]({commit, state, dispatch}, isConnectionError: boolean): void {
        commit(AppMutations.SET_CONNECTION_ERROR, isConnectionError);
    },
    [AppActions.SET_LOGGED_IN]({commit, state, dispatch}, isLoggedIn: boolean): void {
        commit(AppMutations.SET_LOGGED_IN, isLoggedIn);
    },
    [AppActions.SET_PROJECTS]({commit, state, dispatch}, projects: Projects): void {
        commit(AppMutations.SET_PROJECTS, projects);
    },
    [AppActions.SET_VISITED]({commit, state, dispatch}, visited: Array<number>): void {
        commit(AppMutations.SET_VISITED, visited);
    },
    [AppActions.SET_STATS]({commit, state, dispatch}, stats: Stats): void {
        commit(AppMutations.SET_STATS, stats);
    },
    [AppActions.SET_MILESTONES]({commit, state, dispatch}, projects: Array<ObjectData>): void {
        commit(AppMutations.SET_MILESTONES, projects);
    }
}
