import {StoreOptions} from 'vuex';
import Vuex from 'vuex';
import {RootState} from "@/store/types";
import {app} from '@/store/modules/app';

const storeData: StoreOptions<RootState> = {
    state: {},
    modules: {
        app
    }
};

const store = new Vuex.Store<RootState>(storeData);

export default store;
