
import axios from "axios";
import {defineComponent} from 'vue'
import router from '../router';
import {AppActions} from "@/store/modules/app/actions";
import store from "@/store";
import VuexClient from "@/store/VuexClient";

export default defineComponent({
    name: "LogInView",
    components: {},
    data() {
        return {
            username: '',
            password: ''
        }
    },
    computed: {
        isComplete(): string {
            return this.username && this.password;
        }
    },
    methods: {
        attemptLogin(): void {
            const formData = {
                username: this.username,
                password: this.password,
            }
            axios.post(process.env.VUE_APP_API_BASE + 'authenticate/log-in', JSON.stringify(formData)).then(response => {
                const data = response.data;
                if (data.success) {
                    localStorage.setItem('username', this.username);
                    const gitlab_token = localStorage.getItem('token');
                    if (!gitlab_token) {
                        axios.post(process.env.VUE_APP_API_BASE + 'options/get', {
                            name: `gitlab_access_token_${this.username}`,
                            timeline_access_token: data.access_token,
                        }).then(response => {
                            localStorage.setItem('token', response.data);
                        });
                    }
                    store.dispatch(AppActions.SET_LOGGED_IN, true).then(() => {
                        sessionStorage.setItem('timeline_access_token', data.access_token);
                        VuexClient.connectToWebSocket();
                        VuexClient.getProjects();
                        VuexClient.getMilestones();
                        setTimeout(this.refresh, 590000);
                    });
                    router.push('/home');
                }
            }).catch(error => {
                console.debug(error);
            });
        },
        refresh(): void {
            const access_token = sessionStorage.getItem('timeline_access_token');
            axios.post(process.env.VUE_APP_API_BASE + 'authenticate/refresh', {
                timeline_access_token: access_token
            }).then(response => {
                sessionStorage.setItem('timeline_access_token', response.data);
                setTimeout(this.refresh, 590000);
            }).catch(error => {
                VuexClient.destroySession();
            });
        }
    }
});

