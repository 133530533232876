import {GetterTree} from "vuex";
import {AppState, ObjectData, Projects, Stats} from "@/store/modules/app/types";
import {RootState} from "@/store/types";

export const getters: GetterTree<AppState, RootState> = {
    buildingData(state): boolean {
        return state.buildingData;
    },
    connectionError(state): boolean {
        return state.connectionError;
    },
    objectData(state): Array<ObjectData> {
        return state.objectData;
    },
    currentViewList(state): Array<number> {
        return state.currentViewList;
    },
    loggedIn(state): boolean {
        return state.loggedIn;
    },
    projects(state): Projects {
        return state.projects;
    },
    visited(state): Array<number> {
        return state.visited;
    },
    stats(state): Stats {
        return state.stats;
    },
    milestones(state): Array<ObjectData> {
        return state.milestones;
    }
}
