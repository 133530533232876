
import {defineComponent} from 'vue'
import store from "@/store";
import {DirectAccessEntry} from "@/types";
import {viewTitle} from "@/router";
import ThemeSwitch from "@/components/ThemeSwitch.vue";

export default defineComponent({
    name: "Header",
    components: {
        ThemeSwitch
    },
    data() {
        const menuEntries: DirectAccessEntry = {
            home: {
                label: 'Home',
                url: '/home',
            },
            helpdesk: {
                label: 'Helpdesk',
                url: '/list/helpdesk',
            },
            estimate: {
                label: 'Estimate',
                url: '/list/estimate',
            },
            support: {
                label: 'To Do',
                url: '/list/todo',
            },
            doing: {
                label: 'Doing',
                url: '/list/doing',
            },
            invoiceable: {
                label: 'Invoiceable',
                url: '/list/invoiceable',
            },
            unprocessable: {
                label: 'Unprocessable',
                url: '/list/unprocessable',
            },
            others: {
                label: 'Others',
                submenu: [
                    {
                        label: 'All Issues',
                        url: '/list/all',
                    },
                    {
                        label: 'No Milestones',
                        url: '/list/noMilestones',
                    },
                    {
                        label: 'Unestimated',
                        url: '/list/unestimated',
                    },
                    {
                        label: 'Approval',
                        url: '/list/inApproval',
                    }
                ]
            },
            /*
            timeline: {
                label: 'Timeline',
                url: '/timeline',
            },*/
            Settings: {
                label: 'Settings',
                url: '/settings',
            },
        };

        return {
            menuEntries: menuEntries
        }
    },
    computed: {
        currentRouteName: function () {
            if (this.$route.params['listType']) {
                return viewTitle[this.$route.params['listType'].toString()];
            }
            return this.$route.name;
        },
        buildingData(): boolean {
            return store.getters.buildingData;
        },
        connectionError(): boolean {
            return store.getters.connectionError;
        }
    }
});
