
import {Assignee, CompanionPopUpData, TimeStats} from '@/types'
import {defineComponent} from "vue";
import store from "@/store";
import {MetaCollection, ObjectData, TimelineObject} from "@/store/modules/app/types";
import {AppActions} from "@/store/modules/app/actions";

export default defineComponent({
    name: "TicketRow",
    props: {
        ticket: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        view: {
            type: String,
            required: true
        },
        visited: {
            type: Array,
            required: true
        },
        recentlyVisited: {
            type: Array,
            required: true
        },
        assigneeFilter: {
            type: String,
            default: ''
        },
        titleFilter: {
            type: String,
            default: ''
        },
        labelFilter: {
            type: String,
            default: ''
        },
        projectFilter: {
            type: Number,
            default: -1
        },
        provider: {
            type: String,
            default: 'all'
        }
    },
    computed: {
        ticketObject(): TimelineObject {
            if (this.ticket) {
                return this.ticket.object;
            }
            return {
                internal_id: -1,
                external_id: -1,
                title: 'Loading...',
                provider: '',
                type: '',
                modified: ''
            };
        },
        meta(): MetaCollection {
            if (this.ticket) {
                return this.ticket.meta;
            }
            return {
                timeline_labels: '[]',
                timeline_assignee: '[]',
                timeline_time_stats: '[]',
                timeline_link: '',
                timeline_milestone_id: '-1',
                timeline_iid: '-1',
            };
        },
        labels(): Array<string> {
            if (this.meta.timeline_labels) {
                return JSON.parse(this.meta.timeline_labels);
            }
            return [];
        },
        assignees(): Array<Assignee> {
            if (this.meta.timeline_assignee) {
                return JSON.parse(this.meta.timeline_assignee);
            }
            return [];
        },
        timeStats(): TimeStats {
            if (this.meta.timeline_time_stats) {
                return JSON.parse(this.meta.timeline_time_stats);
            }
            return {
                human_time_estimate: 0,
                human_total_time_spent: 0
            };
        },
        link(): string {
            if (this.meta.timeline_link) {
                return this.meta.timeline_link;
            }
            return '';
        },
        milestone(): ObjectData | null {
            if (!this.meta.timeline_milestone_id) {
                return null;
            }
            const milestone_id: number = parseInt(this.meta.timeline_milestone_id);

            const milestones: Array<ObjectData> = store.getters.milestones;

            const milestone = milestones.find((objectData: ObjectData) => {
                if (objectData.object) {
                    return objectData.object.internal_id === milestone_id;
                }
                return false;
            });
            if (milestone) {
                return milestone;
            }
            return null;
        },
        milestoneObject(): TimelineObject | false {
            if (this.milestone) {
                return this.milestone.object;
            }
            return false;
        },
        projectId(): number {
            if (this.meta.timeline_project_id) {
                return parseInt(this.meta.timeline_project_id);
            }
            return -1;
        },
        iid(): number {
            if (this.ticketObject.provider === 'gitlab') {
                return parseInt(this.meta.timeline_iid, 10);
            }
            return this.ticketObject.external_id;
        },
        milestoneMeta() {
            if (this.milestone) {
                return this.milestone.meta;
            }
            return null;
        },
        milestoneLink(): string {
            if (this.milestoneMeta && this.milestoneMeta.timeline_link) {
                return this.milestoneMeta.timeline_link;
            }
            return '';
        },
        showRow(): boolean {
            let show: boolean = true;

            if (this.assigneeFilter.length) {
                if (this.assignees.length) {
                    show = this.assignees.some(assignee => assignee.name.toLowerCase().includes(this.assigneeFilter.toLowerCase()));
                } else {
                    show = false;
                }
            }

            if (this.titleFilter.length) {
                if (this.titleFilter.includes('#')) {
                    show = show && (this.titleFilter === `#${this.iid}`);
                } else {
                    show = show && (this.ticketObject.title + this.iid).toLowerCase().includes(this.titleFilter.toLowerCase());
                }
            }

            if (this.labelFilter.length) {
                show = show && (this.meta.timeline_labels.toLowerCase().includes(this.labelFilter.toLowerCase()));
            }

            if (this.projectFilter > -1) {
                show = show && this.projectId === this.projectFilter;
            }
            return show;
        },
        logoSource(): string {
            if (this.ticketObject && this.ticketObject.provider) {
                return `/assets/img/${this.ticketObject.provider}.png`;
            }
            return '';
        },
    },
    methods: {
        showPopup(): void {
            if (this.provider !== 'zammad') {
                return;
            }

            const ticketData: CompanionPopUpData = {
                ticket_id: this.ticketObject.external_id,
                title: this.ticketObject.title,
                link: this.meta.timeline_link,
            };

            this.$emit('showPopup', ticketData);
        },
        showOrderPopup(): void {
            this.$emit('showOrderPopup', this.index);
        },
        setAssigneeFilter(name: string): void {
            this.$emit('setAssigneeFilter', name);
        },
        setLabelFilter(name: string): void {
            this.$emit('setLabelFilter', name);
        },
        labelClass(label: string): string {
            return label.toLowerCase().replace(/ /g, "_").replace(/::/g, "_");
        },
        addToVisited() {
            const set: Set<number> = new Set(this.visited as Array<number>);
            const recentSet: Set<number> = new Set(this.recentlyVisited as Array<number>);
            set.add(this.ticketObject.internal_id);
            recentSet.add(this.ticketObject.internal_id);
            localStorage.setItem('visited', JSON.stringify(Array.from(set)));
            store.dispatch(AppActions.SET_VISITED, Array.from(recentSet))
            this.$emit('visitedChanged');
        }
    }
});
