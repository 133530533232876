import axios from "axios";
import {AppActions} from "@/store/modules/app/actions";
import store from "@/store/index";
import {ObjectData} from "@/store/modules/app/types";
import {filterKeys} from "@/router";
import router from '../router';
import {RouteLocation} from "vue-router";

/**
 * An interface for the Vuex store
 */
export default class VuexClient {

    public static error: boolean = false;

    private static socket: WebSocket;

    /**
     * Connect to, and maintain connection to the Timeline Websocket
     */
    public static connectToWebSocket(): void {
        VuexClient.error = false;
        const host = process.env.VUE_APP_WEBSOCKET_URL;
        this.socket = new WebSocket(host);
        this.socket.onmessage = (e: MessageEvent) => {
            const data = JSON.parse(e.data);
            const payload = JSON.parse(data.data);
            let key: string = payload[1] || '';

            if (!key.length) {
                VuexClient.connectToWebSocket();
                return;
            }

            const route: RouteLocation = router.currentRoute.value;

            key = key.replace('__keyspace@0__:', '');

            if (route && route.params && route.params['listType'] === filterKeys[key]) {
                VuexClient.getDataFromRedis(key);
            }
        };

        this.socket.onopen = (e) => {
            console.debug(e);
            store.dispatch(AppActions.SET_CONNECTION_ERROR, false);
        }

        this.socket.onclose = (e) => {
            console.debug(e);
            if (e.reason === 'normal closure') {
                return;
            }

            // The only way to detect error is that the close event is not natural,
            // i.e. less than the timeout of the Websocket server.
            // There is an error event, but the close event is also called there,
            // leading do duplicate code execution
            if (VuexClient.error) {
                console.debug(VuexClient.error);
                setTimeout(VuexClient.connectToWebSocket, 5000);
            } else {
                VuexClient.connectToWebSocket();
            }
        };
        this.socket.onerror = (e) => {
            store.dispatch(AppActions.SET_CONNECTION_ERROR, true);
            VuexClient.error = true;
        };
    }

    /**
     * Get data relevant to the current view from redis
     *
     * @param key
     */
    public static getDataFromRedis(key: string): void {
        store.dispatch(AppActions.SET_BUILDING_DATA, true);
        axios.post(process.env.VUE_APP_READ_API_BASE + 'timeline', {
            key: key,
            timeline_access_token: sessionStorage.getItem('timeline_access_token'),
        }, {
            headers: {
                'X-Timeline-Key': '$2a$10$yoK0FllXsMrnI6fNeXywBuzAkSwO4nywC3yTzCh1Akj8bNuQORJQi'
            }
        }).then(response => {
            const ids: Array<number> = JSON.parse(response.data || '[]');
            this.getDiffData(ids);
            store.dispatch(AppActions.SET_CURRENT_VIEW_LIST, ids);
        }).catch(error => {
            if (error.response.status === 401) {
                this.destroySession();
            }
        });
    }

    /**
     * Get all projects from redis
     */
    public static getProjects(): void {
        axios.post(process.env.VUE_APP_READ_API_BASE + 'projects', {
            timeline_access_token: sessionStorage.getItem('timeline_access_token'),
        }, {
            headers: {
                'X-Timeline-Key': '$2a$10$yoK0FllXsMrnI6fNeXywBuzAkSwO4nywC3yTzCh1Akj8bNuQORJQi'
            }
        }).then(response => {
            store.dispatch(AppActions.SET_PROJECTS, response.data || {});
        }).catch(error => {
            if (error.response.status === 401) {
                this.destroySession();
            }
        });
    }

    /**
     * Get all stats from redis
     */
    public static getStats(): void {
        axios.post(process.env.VUE_APP_READ_API_BASE + 'stats', {
            timeline_access_token: sessionStorage.getItem('timeline_access_token'),
        }, {
            headers: {
                'X-Timeline-Key': '$2a$10$yoK0FllXsMrnI6fNeXywBuzAkSwO4nywC3yTzCh1Akj8bNuQORJQi'
            }
        }).then(response => {
            store.dispatch(AppActions.SET_STATS, response.data || {});
        }).catch(error => {
            if (error.response.status === 401) {
                this.destroySession();
            }
        });
    }

    /**
     * Get all milestones from Redis
     */
    public static getMilestones(): void {
        axios.post(process.env.VUE_APP_READ_API_BASE + 'milestones', {
            timeline_access_token: sessionStorage.getItem('timeline_access_token'),
        }, {
            headers: {
                'X-Timeline-Key': '$2a$10$yoK0FllXsMrnI6fNeXywBuzAkSwO4nywC3yTzCh1Akj8bNuQORJQi'
            }
        }).then(response => {
            store.dispatch(AppActions.SET_MILESTONES, response.data || {});
        }).catch(error => {
            if (error.response.status === 401) {
                this.destroySession();
            }
        });
    }

    /**
     * Get the missing object data from redis
     *
     * @param ids
     */
    public static getDiffData(ids: Array<number>): void {
        const currentList: Array<number> = store.getters.currentViewList;
        const diffList: Array<number> = ids.filter(id => !currentList.includes(id));
        if (!diffList.length) {
            store.dispatch(AppActions.SET_BUILDING_DATA, false);
            return;
        }
        axios.post(process.env.VUE_APP_READ_API_BASE + 'batch', {
            ids: JSON.stringify(diffList),
            timeline_access_token: sessionStorage.getItem('timeline_access_token'),
        }, {
            headers: {
                'X-Timeline-Key': '$2a$10$yoK0FllXsMrnI6fNeXywBuzAkSwO4nywC3yTzCh1Akj8bNuQORJQi'
            }
        }).then(response => {
            if (response.data) {
                let objectData: Array<ObjectData>;

                if (!Array.isArray(response.data)) {
                    objectData = JSON.parse(response.data || '[]');
                } else {
                    objectData = response.data;
                }
                const currentData = store.getters.objectData;
                store.dispatch(AppActions.SET_OBJECT_DATA, currentData.concat(objectData)).then(() => {
                    store.dispatch(AppActions.SET_BUILDING_DATA, false);
                });
            }
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                this.destroySession();
            }
        });
    }

    public static destroySession(): void {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        sessionStorage.removeItem('timeline_access_token');
        if (this.socket) {
            this.socket.close(4030);
        }
        store.dispatch(AppActions.SET_BUILDING_DATA, false);
        store.dispatch('setLoggedIn', false);
        router.push('/');
    }
}
