
import {defineComponent} from "vue";
import store from "@/store";
import axios from "axios";
import router from "@/router";
import VuexClient from "@/store/VuexClient";

export default defineComponent({
    name: "SettingsView",
    data() {
        return {
            transientToken: '',
        }
    },
    computed: {
        token(): string {
            const token = localStorage.getItem('token');
            if (token) {
                return token;
            }
            return '';
        },
        username(): string {
            const username = localStorage.getItem('username');
            if (username) {
                return username;
            }
            return '';
        }
    },
    watch: {
        token(newVal: string, oldVal: string): void {
            this.transientToken = newVal;
        },
    },
    methods: {
        updateSettings(): void {
            const savedToken = this.transientToken;
            axios.post(process.env.VUE_APP_API_BASE + 'options/set', JSON.stringify({
                name: 'gitlab_access_token_' + this.username,
                value: savedToken,
                timeline_access_token: sessionStorage.getItem('timeline_access_token'),
            })).then(response => {
                localStorage.setItem('token', savedToken);
            }).catch(error => {
                if(error.response.status === 401) {
                    VuexClient.destroySession();
                }
            });
        }
    },
    mounted() {
        this.transientToken = this.token;
    }
});
