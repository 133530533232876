import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LogInView from "@/views/LogInView.vue";
import TimelineView from "@/views/TimelineView.vue";
import SettingsView from "@/views/SettingsView.vue";
import {KeyMap} from "@/store/types";
import ListView from "@/views/ListView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/home',
        name: 'Stats',
        component: HomeView
    },
    {
        path: '/',
        name: 'LogIn',
        component: LogInView
    },
    {
        path: '/timeline',
        name: 'Timeline',
        component: TimelineView
    },
    {
        path: '/list/:listType',
        name: 'List',
        component: ListView
    },
    {
        path: '/settings',
        name: 'Settings',
        component: SettingsView
    }
];

export const filterKeys: KeyMap = {
    timeline_open_issues: 'all',
    timeline_milestones: 'milestones',
    timeline_issues_without_milestones: 'noMilestones',
    timeline_helpdesk_tickets: 'helpdesk',
    timeline_todo_issues: 'todo',
    timeline_unprocessable_issues: 'unprocessable',
    timeline_estimate_issues: 'estimate',
    timeline_issues_in_approval: 'inApproval',
    timeline_invoiceable_issues: 'invoiceable',
    timeline_issues_without_labels: 'noLabels',
    timeline_unestimated_issues: 'unestimated',
    timeline_doing_issues: 'doing',
};
export const viewTitle: KeyMap = {
    todo: 'To Do',
    estimate: 'Estimate',
    invoiceable: 'Invoiceable',
    unprocessable: 'Unprocessable',
    inApproval: 'Approval',
    all: 'Open issues',
    noLabels: 'No Labels',
    noMilestones: 'No Milestones',
    helpdesk: 'Helpdesk',
    unestimated: 'Unestimated',
    doing: 'Doing',
};

export const viewProvider: KeyMap = {
    todo: 'gitlab',
    estimate: 'gitlab',
    unprocessable: 'gitlab',
    invoiceable: 'gitlab',
    inApproval: 'gitlab',
    all: 'all',
    noMilestones: 'gitlab',
    helpdesk: 'zammad',
    unestimated: 'gitlab',
    doing: 'gitlab',
};

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
