
import {defineComponent} from 'vue';
import {HomeData} from "@/types";
import store from "@/store";
import {Stats} from "@/store/modules/app/types";

export default defineComponent({
    name: 'HomeView',
    components: {},
    computed: {

        unestimated(): number {
            if (this.stats.unestimated) {
                return this.stats.unestimated;
            }
            return 0;
        },
        noLabels(): number {
            if (this.stats.noLabels) {
                return this.stats.noLabels;
            }
            return 0;
        },
        noMilestones(): number {
            if (this.stats.noMilestones) {
                return this.stats.noMilestones;
            }
            return 0;
        },
        blockedIssues(): number {
            if (this.stats.blocked) {
                return this.stats.blocked;
            }
            return 0;
        },
        issuesInApproval(): number {
            if (this.stats.approval) {
                return this.stats.approval;
            }
            return 0;
        },
        supportIssues(): number {
            if (this.stats.supportIssues) {
                return this.stats.supportIssues;
            }
            return 0;
        },
        stats(): Stats {
            return store.getters.stats;
        },
        statCards(): HomeData {
            return {
                todo: {
                    label: 'To Do tickets',
                    value: this.supportIssues
                },
                unestimated: {
                    label: 'Unestimated tickets',
                    value: this.unestimated
                },
                issuesInApproval: {
                    label: 'Tickets in approval',
                    value: this.issuesInApproval
                },
                blockedIssues: {
                    label: 'Blocked tickets',
                    value: this.blockedIssues
                },
                noLabels: {
                    label: 'Tickets without any labels',
                    value: this.noLabels
                },
                noMilestones: {
                    label: 'Tickets without milestone',
                    value: this.noMilestones
                },
            }
        }
    }
})
