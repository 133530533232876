
import axios from 'axios';
import router from './router';
import {filterKeys} from './router';
import store from "@/store";
import HomeView from "@/views/HomeView.vue";
import Header from "@/components/Header.vue";
import {defineComponent} from "vue";
import {AppActions} from "@/store/modules/app/actions";
import VuexClient from './store/VuexClient';
import {RouteLocation} from "vue-router";
import PackageJson from '../package.json';

export default defineComponent({
    components: {
        Header,
        HomeView
    },
    data() {
        return {
            packageInfo: PackageJson
        }
    },
    computed: {
        loggedIn(): boolean {
            return store.getters.loggedIn;
        },
        buildingData(): boolean {
            return store.getters.buildingData;
        },
        connectionError(): boolean {
            return store.getters.connectionError;
        }
    },
    watch: {
        $route(to, from): void {
            if (to !== from && to.path !== '/') {
                if (to.params['listType'] && Object.values(filterKeys).includes(to.params['listType'])) {
                    store.dispatch(AppActions.SET_CURRENT_VIEW_LIST, []).then(() => {
                        this.initializeRouteView(to);
                    });
                } else if (to.path === '/home') {
                    VuexClient.getStats();
                }
            }
        }
    },
    methods: {
        initializeRouteView(route: RouteLocation): void {
            if (this.buildingData || this.connectionError) {
                return;
            }
            const matchedRouteKey = Object.keys(filterKeys).find((key: string) => filterKeys[key] === route.params['listType']) || '';
            if (matchedRouteKey.length) {
                VuexClient.getDataFromRedis(matchedRouteKey);
            }
        },
        refresh(): void {
            const access_token = sessionStorage.getItem('timeline_access_token');
            axios.post(process.env.VUE_APP_API_BASE + 'authenticate/refresh', {
                timeline_access_token: access_token
            }).then(response => {
                sessionStorage.setItem('timeline_access_token', response.data);
                setTimeout(this.refresh, 590000);
            }).catch(error => {
                VuexClient.destroySession();
            });
        }
    },
    beforeMount(): void {
        document.addEventListener("visibilitychange", (e: Event) => {
            if (document.visibilityState === 'visible') {
                this.initializeRouteView(this.$route)
            }
        });
        axios.post(process.env.VUE_APP_API_BASE + 'authenticate', {
            timeline_access_token: sessionStorage.getItem('timeline_access_token'),
        }).then(response => {
            if (response.data === 'Success') {
                store.dispatch(AppActions.SET_LOGGED_IN, true).then(() => {
                    VuexClient.connectToWebSocket();
                    VuexClient.getProjects();
                    VuexClient.getMilestones();
                    this.refresh();
                });
                if (typeof this.$route.name === 'undefined' || this.$route.name === 'LogIn') {
                    router.push('/home');
                }
                const token = localStorage.getItem('token');
                const username = localStorage.getItem('username');
                if (!token && username) {
                    axios.post(process.env.VUE_APP_API_BASE + 'options/get', {
                        name: `gitlab_access_token_${username}`,
                        timeline_access_token: sessionStorage.getItem('timeline_access_token'),
                    }).then(response => {
                        localStorage.setItem('token', response.data);
                    });
                }
            } else {
                VuexClient.destroySession();
            }
        }).catch(error => {
            VuexClient.destroySession();
        });
    }
});
