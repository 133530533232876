
import {defineComponent} from "vue";
import {ThemeData} from "@/types";

export default defineComponent({
    name: 'ThemeSwitch',
    data: function () {
        const themeData: ThemeData = {
            theme: 'light'
        };
        return themeData;
    },
    methods: {
        switchTheme(): void {
            if (this.theme === 'dark') {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
                this.theme = 'light';
            } else {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark');
                this.theme = 'dark';
            }
        }
    },
    mounted() {
        const themeQueryDark: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

        if (localStorage.getItem('theme')) {
            this.theme = localStorage.getItem('theme') || '';
        } else if (themeQueryDark.matches) {
            this.theme = 'dark';
        }

        document.documentElement.setAttribute('data-theme', this.theme);
    }
});
