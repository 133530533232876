
import {defineComponent} from "vue";
import axios from "axios";
import store from "@/store";
import {Projects} from "@/store/modules/app/types";
import router from "@/router";
import VuexClient from "@/store/VuexClient";

export default defineComponent({
    name: "CreateCompanionIssuePopup",
    props: {
        show: {
            type: Boolean,
            required: true
        },
        ticket: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            projectFilter: '',
            addLabel: false,
            selectedType: 'Type::Support',
            status: '',
            selectedProject: -1,
            users: [
                {
                    fullName: 'Andi',
                    name: 'akruse',
                },
                {
                    fullName: 'Axel',
                    name: 'amengdehl',
                },
                {
                    fullName: 'Christian',
                    name: 'cdutz',
                },
                {
                    fullName: 'Daniel',
                    name: 'dstorm',
                },
                {
                    fullName: 'Georg',
                    name: 'gschabel',
                },
                {
                    fullName: 'Peter',
                    name: 'pschmidt',
                },
                {
                    fullName: 'Tibor',
                    name: 'tbalazs',
                },
            ],
            typeLabels: [
                'Bug',
                'Feature',
                'Support',
                'New Customer',
            ],
            url: '',
            selectedAssignee: 'amengdehl'
        }
    },
    computed: {
        projects(): Projects {
            const projects = store.getters.projects;
            if (projects) {
                return projects;
            }
            return {};
        }
    },
    methods: {
        hidePopup(): void {
            this.selectedProject = -1;
            this.projectFilter = '';
            this.status = '';
            this.selectedAssignee = 'amengdehl';
            this.selectedType = 'Type::Support';
            this.addLabel = false;
            this.url = '';
            this.$emit('hidePopup');
        },
        createTicket(): void {
            const title: string = this.ticket.title || '';
            const ticketId: number = parseInt(this.ticket.ticket_id || '', 10);
            const link: string = this.ticket.link || '';

            if (isNaN(this.selectedProject) || this.selectedProject <= 0) {
                this.status = 'Please give the project id';
                return;
            }

            const token = localStorage.getItem('token') || '';
            if (!token.length) {
                this.status = 'API token not set!';
                return;
            }

            if (!title.length || isNaN(ticketId) || ticketId <= 0 || !link.length || !this.selectedAssignee.length) {
                this.status = 'Values missing. Shouldn\'t see this unless hacking or Tibor can be paddled';
                return;
            }
            this.status = 'Creating GitLab issue...';
            axios.post(process.env.VUE_APP_API_BASE + 'issues/create', JSON.stringify({
                project_id: this.selectedProject,
                title: title,
                is_billing: this.addLabel,
                type: this.selectedType,
                link: link,
                token: token,
                assignee: this.selectedAssignee,
                timeline_access_token: sessionStorage.getItem('timeline_access_token'),
            })).then(response => {
                if (response.data.length) {
                    this.url = response.data;
                    this.status = 'Linking GitLab issue to the Zammad ticket...';
                    this.linkZammadTicket(ticketId, this.url);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    VuexClient.destroySession();
                } else {
                    this.status = 'Failed to create Gitlab ticket';
                }
            });
        },
        linkZammadTicket(ticket_id: number, gitlabLink: string): void {
            axios.post(process.env.VUE_APP_API_BASE + 'tickets/link', JSON.stringify({
                ticket_id: ticket_id,
                gitlab_link: gitlabLink,
                timeline_access_token: sessionStorage.getItem('timeline_access_token'),
            })).then(response => {
                if (response.data.length) {
                    this.status = 'Done!';
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    VuexClient.destroySession();
                } else {
                    this.status = 'Failed to link Gitlab ticket';
                }
            });
        },
        setProjectFilter(id: number, name: string): void {
            this.projectFilter = name;
            this.selectedProject = id;
        }
    },
    watch: {
        projectFilter(newVal: string, oldVal: string): void {
            if (newVal.length < oldVal.length && this.selectedProject !== -1) {
                this.selectedProject = -1;
            }
        }
    }
});
