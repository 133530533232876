import {mutations} from './mutations';
import {actions} from './actions';
import {Module} from "vuex";
import {RootState} from "@/store/types";
import {AppState, Stats} from "@/store/modules/app/types";
import {getters} from "@/store/modules/app/getters";

const state: AppState = {
    objectData: [],
    connectionError: false,
    currentViewList: [],
    buildingData: false,
    loggedIn: false,
    stats: {},
    projects: [],
    visited: [],
    milestones: []
};

export const app: Module<AppState, RootState> = {
    state,
    mutations,
    actions,
    getters,
};
