
import TicketTable from "@/components/TicketTable.vue";
import {defineComponent} from "vue";
import {viewProvider} from "@/router";

export default defineComponent({
    name: "ListView",
    components: {
        TicketTable
    },
    computed: {
        viewName(): string {
            return this.$route.params.listType.toString() || '';
        }
    }
});
