import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon"
}
const _hoisted_2 = {
  key: 1,
  class: "icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "theme-switch",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchTheme && _ctx.switchTheme(...args)))
  }, [
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.theme === 'dark')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "dark_mode"))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "light_mode"))
      ]),
      _: 1
    })
  ]))
}