import {MutationTree} from "vuex";
import {AppState, ObjectData, Projects, Stats} from "@/store/modules/app/types";

export enum AppMutations {
    SET_OBJECT_DATA = 'SET_OBJECT_DATA',
    SET_CURRENT_VIEW_LIST = 'SET_CURRENT_VIEW_LIST',
    SET_BUILDING_DATA = 'SET_BUILDING_DATA',
    SET_CONNECTION_ERROR = 'SET_CONNECTION_ERROR',
    SET_LOGGED_IN = 'SET_LOGGED_IN',
    SET_PROJECTS = 'SET_PROJECTS',
    SET_VISITED = 'SET_VISITED',
    SET_STATS = 'SET_STATS',
    SET_MILESTONES = 'SET_MILESTONES'
}

export const mutations: MutationTree<AppState> = {
    [AppMutations.SET_OBJECT_DATA](state, objectData: Array<ObjectData>): void {
        state.objectData = objectData;
    },
    [AppMutations.SET_CURRENT_VIEW_LIST](state, viewList: Array<number>): void {
        state.currentViewList = viewList;
    },
    [AppMutations.SET_BUILDING_DATA](state, isBuildingData: boolean): void {
        state.buildingData = isBuildingData;
    },
    [AppMutations.SET_CONNECTION_ERROR](state, isConnectionError: boolean): void {
        state.connectionError = isConnectionError;
    },
    [AppMutations.SET_LOGGED_IN](state, isLoggedIn: boolean): void {
        state.loggedIn = isLoggedIn;
    },
    [AppMutations.SET_PROJECTS](state, projects: Projects): void {
        state.projects = projects;
    },
    [AppMutations.SET_VISITED](state, visited: Array<number>): void {
        state.visited = visited;
    },
    [AppMutations.SET_STATS](state, stats: Stats): void {
        state.stats = stats;
    },
    [AppMutations.SET_MILESTONES](state, milestones: Array<ObjectData>): void {
        state.milestones = milestones;
    },
}
