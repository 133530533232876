import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-main" }
const _hoisted_2 = { class: "app-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loggedIn)
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("footer", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.packageInfo.name), 1),
      _createElementVNode("span", null, _toDisplayString('v' + _ctx.packageInfo.version), 1)
    ])
  ], 64))
}