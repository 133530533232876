
import {defineComponent} from "vue";
import store from "@/store";

export default defineComponent({
    name: "ReorderTicketPopup",
    props: {
        index: {
            type: Number,
            required: true
        },
        show: {
            type: Boolean,
            required: true
        },
        lastSetIndex: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            transientIndex: -1,
        }
    },
    computed: {
        buildingData(): boolean {
            return store.getters.buildingData;
        },
        connectionError(): boolean {
            return store.getters.connectionError;
        }
    },
    methods: {
        hidePopup(): void {
            this.$emit('hidePopup');
        },
        changeOrder(): void {
            if (this.buildingData || this.connectionError) {
                return;
            }
            this.$emit('changeOrder', {
                oldIndex: this.index - 1,
                newIndex: this.transientIndex - 1
            });
            this.hidePopup();
        },
        setTransientIndex(): void {
            if (this.lastSetIndex > 0) {
                this.transientIndex = this.lastSetIndex;
            } else {
                this.transientIndex = this.index;
            }
        }
    },
    mounted() {
        this.setTransientIndex();
    },
    watch: {
        index(): void {
            this.setTransientIndex();
        },
        show(): void {
            if (this.show) {
                const input: HTMLElement = (this.$refs.input as HTMLElement);
                setTimeout(() => {
                    input.focus();
                }, 0);
            }
        }
    }
});
