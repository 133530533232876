
import TicketRow from "@/components/TicketRow.vue";
import CreateCompanionIssuePopup from "@/components/CreateCompanionIssuePopup.vue";
import {VueDraggableNext} from 'vue-draggable-next'
import store from "@/store";
import {defineComponent} from "vue";
import axios from "axios";
import {CompanionPopUpData} from "@/types";
import ReorderTicketPopup from "@/components/ReorderTicketPopup.vue";
import {ObjectData, Projects} from "@/store/modules/app/types";
import {AppActions} from "@/store/modules/app/actions";
import router, {viewProvider} from "@/router";
import VuexClient from "@/store/VuexClient";

interface MovedData {
    element: string,
    oldIndex: number,
    newIndex: number
}

interface ManualMoveData {
    oldIndex: number,
    newIndex: number
}


interface MovedEvent extends Event {
    moved: MovedData
}

export default defineComponent({
    name: "TicketTable",
    components: {
        ReorderTicketPopup,
        TicketRow,
        CreateCompanionIssuePopup,
        draggable: VueDraggableNext
    },
    data() {
        const selectedTicket: CompanionPopUpData = {
            ticket_id: -1,
            title: '',
            link: ''
        };
        const visited: Array<number> = [];
        return {
            localObjects: [],
            assigneeFilter: '',
            titleFilter: '',
            labelFilter: '',
            showPopup: false,
            showOrderPopup: false,
            selectedTicket: selectedTicket,
            selectedIndex: -1,
            projectFilter: '',
            selectedProject: -1,
            lastSetIndex: -1,
            visited: visited
        }
    },
    props: {
        view: {
            type: String,
            required: true
        }
    },
    computed: {
        buildingData(): boolean {
            return store.getters.buildingData;
        },
        connectionError(): boolean {
            return store.getters.connectionError;
        },
        currentViewList: {
            get(): Array<number> {
                return store.getters.currentViewList;
            },
            set(newValue: Array<number>): void {
                store.dispatch(AppActions.SET_CURRENT_VIEW_LIST, newValue);
            },
        },
        projects(): Projects {
            const projects = store.getters.projects;
            if (projects) {
                return projects;
            }
            return {};
        },
        provider(): string {
            if (this.view && this.view.length) {
                return viewProvider[this.view];
            }
            return 'all';
        },
        recentlyVisited(): Array<number> {
            const visited = store.getters.visited;
            if (visited) {
                return visited;
            }
            return [];
        }
    },
    methods: {
        orderChanged(event: MovedEvent): void {
            this.sendSortingCall(this.currentViewList[event.moved.newIndex], event.moved.oldIndex, event.moved.newIndex);
            this.$emit('changed');
        },
        sendSortingCall(internalId: number, oldIndex: number, newIndex: number): void {
            store.dispatch(AppActions.SET_BUILDING_DATA, true);
            axios.post(process.env.VUE_APP_API_BASE + 'objects/set-order', {
                internal_id: internalId,
                new_index: newIndex + 1,
                old_index: oldIndex + 1,
                view: this.view,
                timeline_access_token: sessionStorage.getItem('timeline_access_token'),
            }).then(response => {
                this.lastSetIndex = newIndex + 2;
                store.dispatch(AppActions.SET_BUILDING_DATA, false);
            }).catch(error => {
                if (error.response.status === 401) {
                    VuexClient.destroySession();
                } else {
                    alert('Sorting failed');
                    window.location.href = window.location.href;
                }
            });
        },
        displayPopup(data: CompanionPopUpData): void {
            this.showPopup = true;
            this.selectedTicket = data;
        },
        hidePopup(): void {
            this.showPopup = false;
        },
        hideOrderPopup(): void {
            this.showOrderPopup = false;
        },
        displayOrderPopup(index: number): void {
            this.showOrderPopup = true;
            this.selectedIndex = index;
        },
        updateOrder(data: ManualMoveData): void {
            if (this.buildingData || this.connectionError) {
                return;
            }
            const objectId = this.currentViewList.splice(data.oldIndex, 1)[0];
            this.currentViewList.splice(data.newIndex, 0, objectId);
            this.sendSortingCall(objectId, data.oldIndex, data.newIndex);
        },
        setProjectFilter(id: string, name: string): void {
            this.projectFilter = name;
            this.selectedProject = parseInt(id, 10);
        },
        getObject(id: number): ObjectData | null {
            const objectData: Array<ObjectData> = store.getters.objectData;
            return objectData.find((data: ObjectData) => data.object.internal_id === id) || null;
        },
        setAssigneeFilter(name: string): void {
            this.assigneeFilter = name;
        },
        setLabelFilter(name: string): void {
            this.labelFilter = name;
        },
        getVisited(): void {
            const visited: string = localStorage.getItem('visited') || '[]';
            this.visited = JSON.parse(visited);
        }
    },
    watch: {
        projectFilter(newVal: string, oldVal: string): void {
            if (newVal.length < oldVal.length && this.selectedProject !== -1) {
                this.selectedProject = -1;
            }
        },
        view(): void {
            this.lastSetIndex = -1;
        }
    },
    mounted() {
        this.getVisited();
    }
});
